<template>
  <div class="colorBackground">
    
    <div class="warning" >
      <p style="font-size: 12px;">! 版本:{{versionData}}</p>
    </div>

    <div class="el-row">
      <div class="el-col el-col-12">
        <div class="border">
          <h4 style="font-size: 12px;">
              机构编码
            <div style="float: right">{{userDetail.companyCode}}</div>
          </h4>
        </div>
      </div>
      <div class="el-col el-col-12">
        <div class="border">
          <h4 style="font-size: 12px;">
              陪练时长
            <div style="float: right">{{userDetail.totalTime}}</div>
          </h4>
        </div>
      </div>
    </div>
    <div class="el-row">
      <div class="el-col-8">
        <div class="border2" style="display: flex;align-items: center;justify-content: space-between;">
          <h4 style="font-size: 12px;">
            30分钟时长
            <span style="margin-left: 5vw;font-size: 15px;" >{{userDetail.threeTime}}</span>
          </h4>
          <el-button  @click="sixTothree"  type="primary" size="small" >
          兑换
        </el-button>
        </div>
      </div>
      <div class="el-col-8">
        <div class="border2">
          <h4 style="font-size: 12px;">
            60分钟时长
            <span style="float: right;font-size: 15px;">{{userDetail.sixTime}}</span>
          </h4>
        </div>
      </div>
      <div class="el-col-8">
        <div class="border2">
          <h4 style="font-size: 12px;">
            体验时长
            <span style="float: right;font-size: 15px;" >{{userDetail.trialClassTime}}</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="el-row">
      <div class="border3">
        <span class="label" style="font-size: 12px;">上级机构</span>
        <el-table  :data="tableData" style="width: 100%">
          <el-table-column label="序号" width="120" show-overflow-tooltip>1
          </el-table-column>
          <el-table-column property="companyName" label="机构名" width="120"  show-overflow-tooltip/>
          <el-table-column
            property="companyCode"
            label="机构编号"
            show-overflow-tooltip
          />
          <el-table-column
            property="companyUserName"
            label="负责人"
            show-overflow-tooltip
          />
        </el-table>
      </div>
      <div class="border3">
        <span class="label" style="font-size: 12px;">交付中心</span>
        <el-table  :data="tableData" style="width: 100%">
          <el-table-column label="序号" width="120" show-overflow-tooltip>
            <template >1</template>
          </el-table-column>
          <el-table-column property="name" label="交付中心" width="120"  show-overflow-tooltip/>
          <el-table-column
            property="companyCode"
            label="机构编号"
            show-overflow-tooltip
          />
          <el-table-column
            property="address"
            label="负责人"
            show-overflow-tooltip
          />
        </el-table>
      </div>
    </div>
    <div class="el-row">
      <div class="border4">
        <span class="label">充值记录</span>
        <el-table  :data="searchRecord" style="width: 100%">
          <el-table-column label="序号" width="120" show-overflow-tooltip>1
          </el-table-column>
          <el-table-column property="time" label="充值时间" width="120"  show-overflow-tooltip/>
          <el-table-column
            property="type"
            width="100"
            label="充值类型"
            show-overflow-tooltip
          />
          <el-table-column
          width="100"
            property="number"
            label="充值数量"
            show-overflow-tooltip
          />
          <el-table-column
            property="money"
            width="100"
            label="金额"
            show-overflow-tooltip
          />
          <el-table-column
          width="100"
            property="name"
            label="售出方"
            show-overflow-tooltip
          />
          <el-table-column
            property="studentAfter"
            label="充值前"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="studentBefore"
            width="100"
            label="售出后"
            show-overflow-tooltip
          />
          <el-table-column
            property="people"
            width="100"
            label="购入方"
            show-overflow-tooltip
          />
          <el-table-column
            property="companyBefore"
            label="充值前"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="companyAfter"
            label="售出后"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="moneyType"
            label="付款方式"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="type"
            label="充值方式"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="method"
            label="方式"
            width="100"
            show-overflow-tooltip
          />
          <el-table-column
            property="companyAdmin"
            label="操作者"
            width="100"
            show-overflow-tooltip
          />
          
          
        </el-table>
      </div>
      <el-dialog v-model="centerDialogVisible" title="兑换" width="30%" center>
        <el-input v-model="input" placeholder="请输入需要兑换的数量" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitSixToThree"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
      <!-- <div class="border3">
        <span class="label">售出记录</span>
        <el-table  :data="tableData" style="width: 100%">
          <el-table-column label="交易时间" width="120" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.date }}</template>
          </el-table-column>
          <el-table-column property="name" label="机构" width="120"  show-overflow-tooltip/>
          <el-table-column
            property="code"
            label="用户"
            show-overflow-tooltip
          />
          
        </el-table>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
import { getRecord ,getVersion,getCompanyDetail,updateCompanyThreeClass} from '@/utils/api';

//  import { reactive, ref } from 'vue'

export default {
  name: "HOME",
  methods: {
    submitSixToThree(){
     
     
      if ( parseInt(this.input)  > parseInt(this.userDetail.sixTime)){
          alert('60分钟课程数量不足！')
      }else{
        
        updateCompanyThreeClass({
          companyName:JSON.parse(sessionStorage.getItem("login"))[0]["companyName"],
          sixTime:this.input

        }).then((response) => {
         console.log(response)
         location.reload()


        }
         

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

      }

      // this.centerDialogVisible= false

    },
    sixTothree(){
      this.centerDialogVisible= true
    },
    getRecordList(){
      var that = this
      console.log("money")
      getRecord({
        name: '',
        people: JSON.parse(sessionStorage.getItem("login"))[0]["companyName"],
        time:'',
        type:'',
        number:10,
        page:1
      })
      .then((response) => {
        console.log(response)
             that.searchRecord.push( response.msg.data[0])
             sessionStorage.setItem("money", JSON.stringify(response.data) )
             console.log(response.data[0])
       
        }
         
        // 
        // 

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

    }

  },
  data() {
    return {

    tableData:[] ,
    userDetail:[],
    upcompany:[],
    searchRecord:[],
    versionData:'',
    centerDialogVisible:false,
    input:''
          

}
},
created(){
  // this.userDetail = JSON.parse(sessionStorage.getItem("login")) 

  this.tableData = JSON.parse(sessionStorage.getItem("upcompany"))
  this.getRecordList()
      getVersion()
          .then((response) => {
              console.log(response)
              this.versionData = response.data[0].version
            }
            
            // 
            // 

          )
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
      })

       getCompanyDetail({companyName:JSON.parse(sessionStorage.getItem("login"))[0]["companyName"]}).then((response) => {
          this.userDetail =  response.data


        }
         

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

}
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.colorBackground {
  background: #fff;
  height:550px;
  overflow: hidden;

}

.el-row {
  position: relative;
}
.el-col {
  border-radius: 4px;
  /* border: 1px solid #ccc; */
  background: #fff;
}
.el-col-12 {
  width: 30%;
}
.warning {
  padding: 1px 10px;
  background-color: #fff6f7;
  border-radius: 4px;
  border-left: 5px solid #fe6c6f;
  /* margin: 20px 20px 0; */
}
.border {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  margin: 10px;
  padding: 10px;
}
.el-col-8 {
  width: 33%;
}
.border2 {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  margin: 10px;
  padding: 10px;
}
.icon-shenghuodianmianduihuan:before {
  content: "\E645";
}
.label {
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
}
.border3 {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  margin-left: 5px;
  padding: 10px;
  margin-top:10px;
  width: 47%;
}
.border4 {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  margin-left: 5px;
  padding: 10px;
  margin-top:10px;
  width: 97%;
}
</style>
  