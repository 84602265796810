import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'amfe-flexible';
import * as Icons from '@element-plus/icons-vue'
import zhCn from "element-plus/es/locale/lang/zh-cn";
// app.config.globalProperties.$icon = Icons
const app = createApp(App);
//引用vant

// app.use(Vant);

app.use(ElementPlus,{ locale: zhCn})
for (const [iconName, icon] of Object.entries(Icons)) {
    app.component(iconName, icon)
  }
app.use(router).mount('#app');

