<template>
  <div :class="isMobile == true?'colorBackgroundMobile':'colorBackgroundPC'">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="code">
        <el-input
          placeholder="手机号"
          v-model="ruleForm.code"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="name">
        <el-input
          placeholder="姓名"
          v-model="ruleForm.name"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="sex">
        <el-input
          placeholder="性别"
          v-model="ruleForm.sex"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="type">
        <el-input
          placeholder="复习方式"
          v-model="ruleForm.type"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          查询
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 10px;">
      <el-button   type="primary" @click="addstudent" style="margin-left: 20px">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%; height: 26vw">
      <!-- <el-table-column type="selection" width="55" /> -->

      <el-table-column fixed prop="date" width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column width="150" property="name" label="用户" />
      <el-table-column width="140" label="操作">
        <template #default="scope">
          <!-- 操作菜单 -->
          <el-dropdown
            size="small"
            trigger="click"
            split-button
            type="primary"
            @command="handleCommand"
          >
            操作

            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="a" @click="handleEdit(scope.$index)"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="b" @click="openClass(scope.$index)"
                  >开通课程</el-dropdown-item
                >
                <el-dropdown-item command="c" @click="reachaege(scope.$index)"
                  >充值</el-dropdown-item
                >
                <el-dropdown-item command="d" @click="outReachage(scope.$index)"
                  >退费</el-dropdown-item
                >
                <el-dropdown-item command="e"  @click="updateStudentPassWordAction(scope.$index)">修改密码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column width="150" property="phone" label="手机号" />
      <el-table-column
        width="150"
        property="companyName"
        label="机构"
        show-overflow-tooltip
      />
      <el-table-column width="150" property="number" label="陪练时长" />
      <el-table-column width="150" property="sixClass" label="60分钟时长" />
      <el-table-column width="150" property="thirtyClass" label="30分钟时长" />
      <el-table-column width="150" property="trialClass" label="体验课时长" />
      <el-table-column width="150" property="partnerMethod" label="复习方式" />
      <el-table-column width="150" property="sex" label="性别" />
      <el-table-column width="150" property="age" label="年龄" />
      <el-table-column width="150" property="testDate" label="创建时间" />
    </el-table>


      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="studentcount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="margin-left: 20px;height: 45px;;"
      />

    <!-- 充值 -->
    <el-dialog v-model="dialogVisibleRecharge" title="充值" width="800px"   @close="handleClose" >
      <span>用户姓名 ：{{ title }}</span>
      <div class="rechargeClass">
        充值类型:
        <el-radio-group
          v-model="radio"
          @change="radioChanege"
          style="margin-left: 10px"
        >
          <el-radio :value="1" border>30分钟</el-radio>
          <el-radio :value="2" border>60分钟</el-radio>
          <el-radio :value="3" border>体验</el-radio>
          <el-radio :value="4" border>陪练</el-radio>
        </el-radio-group>
      </div>
      <div class="rechargeInput">
        <div>充值数量 :</div>

        <el-input
          v-model="input2"
          style="max-width: 300px; margin-left: 10px"
          @input="handleInput"
          placeholder="请输入1-200之间的整数"
        >
          <template #append>节</template>
        </el-input>
        <div style="color: red; margin-left: 10px">
          大写 : {{ input2Chinsese }}
        </div>
      </div>
      <div>
        <div class="rechargeInput" style="margin-top: 20px">
          <div>充值金额 :</div>

          <el-input
            v-model="input3"
            style="max-width: 300px; margin-left: 10px"
            @input="handleInput2"
            placeholder="请输入1-200之间的整数"
          >
            <template #append>节</template>
          </el-input>
          <div style="color: red; margin-left: 10px">
            大写 : {{ input3Chinsese }}元
          </div>
        </div>
      </div>

      <div style="margin-top: 10px">
        付款方式:
        <el-select
          v-model="SelectValue"
          placeholder="请选择"
          size="default"
          style="width: 240px; margin-left: 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleClose" >重置</el-button>
          <el-button type="primary" @click="insetMoney"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 退费 -->

    <el-dialog v-model="dialogVisibleRechargeOut" title="退费" width="800px">
      <span>用户姓名 ：{{ title }}</span>
      <div class="rechargeClass" style="margin-top: 20px">
        充值类型:
        <el-radio-group
          v-model="radioOut"
          @change="radioChanegeOut"
          style="margin-left: 10px"
        >
          <el-radio :value="1" border>30分钟</el-radio>
          <el-radio :value="2" border>60分钟</el-radio>
          <el-radio :value="4" border>陪练</el-radio>
          <el-radio :value="3" border>体验课</el-radio>
        </el-radio-group>
      </div>
      <div class="rechargeInput" style="margin-top: 25px;">
        <div>退费数量 :</div>

        <el-input
          v-model="inputOut2"
          @input="handleInputtuifei"
          style="max-width: 300px; margin-left: 10px"
          placeholder="请输入1-200之间的整数"
        >
          <template #append>节</template>
        </el-input>
      </div>
      <div>
        <div class="rechargeInput" style="margin-top: 20px">
          <div>备注 :</div>

          <el-input
            v-model="inputOut3"
            style="max-width: 300px; margin-left: 35px"
            maxlength="3"
            placeholder="备注"
          >
          </el-input>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button>重置</el-button>
          <el-button type="primary" @click="outMoney"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 开通课程 -->
    <el-dialog v-model="dialogVisibleBook" title="开通课程 " width="800px">
      <div style="display: flex">
        <div class="rechargeInput" style="margin-top: 10px">
          <el-input
            v-model="inputOutClass"
            style="max-width: 240px; margin-left: 10px"
            maxlength="3"
            placeholder="请输入课程名"
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          @click="submitFormClass"
          style="margin-left: 20px; margin-top: 10px"
        >
          查询
        </el-button>
        <el-button
          @click="resetFormCLass"
          style="margin-left: 20px; margin-top: 10px"
          >重置</el-button
        >
      </div>

      <div style="display: flex">
        <el-table

          :data="tableDataClass"
          style="width: 50%; height:300px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
       

          <el-table-column fixed width="70" label="序号">
            <template #default="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="名称" width="110" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template #default="">
              <el-button type="primary" size="small" @click="openClassDetail"
                >开通</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            property="allNumber"
            label="数量"
            show-overflow-tooltip
          />
        </el-table>
        
        <el-table

        :data="studentclass"
        style="width: 40%; height:300px;margin-left: 10%"

        >


        <el-table-column label="已开通课程" width="120">
          <template #default="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button type="danger" size="small" @click="deleteClassDetail(scope.row.name)"
              >取消</el-button
            >
          </template>
        </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button >重置</el-button>
          <el-button type="primary" @click="openClassDetail"> 开通 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 新增学员 -->
    <el-dialog
      v-model="dialogVisibleRaddStudent"
      title="新增学员"
      width="800px"
    >
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">所属机构 :</div>

        <el-input
          v-model="addInput"
          style="max-width: 300px; margin-left: 10px"
          placeholder="所属机构"
          disabled
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">姓名:</div>
        <el-input
          v-model="addname"
          style="max-width: 300px; margin-left: 10px"
          placeholder="姓名"
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年龄:</div>
        <el-input
          v-model="addage"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年龄"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">性别 :</div>
        <el-radio-group
          v-model="addsexradio"
          style="margin-left: 10px"
        >
          <el-radio :value="1">男</el-radio>
          <el-radio :value="2">女</el-radio>
        </el-radio-group>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">学校 :</div>
        <el-input
          v-model="addschool"
          style="max-width: 300px; margin-left: 10px"
          placeholder="学校"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年级 :</div>
        <el-input
          v-model="addclassage"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年级"
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">手机号码 :</div>
        <el-input
          v-model="addphone"
          style="max-width: 300px; margin-left: 10px"
          placeholder="手机号码"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">状态 :</div>
        <el-radio-group
          v-model="addstateradio"
          style="margin-left: 10px"
        >
          <el-radio :value="1">启用</el-radio>
          <el-radio :value="2">停用</el-radio>
        </el-radio-group>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button >重置</el-button>
          <el-button type="primary" @click="insetStuendt"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog v-model="dialogVisibleupdatePassword" title="修改密码 " width="800px">
      <el-form
    ref="ruleFormRef"
    style="max-width: 600px"
    :model="ruleFormPasword"
    status-icon
    label-width="auto"
    class="demo-ruleForm"
  >
    <el-form-item label="新密码" prop="pass">
      <el-input v-model="ruleFormPasword.pass"  autocomplete="off" />
    </el-form-item>
    <el-form-item label="确认密码" prop="checkPass">
      <el-input
        v-model="ruleFormPasword.checkPass"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitFormUpdatePassword">
        提交
      </el-button>
      <el-button @click="resetFormUpdatePassword">重置</el-button>
    </el-form-item>
  </el-form>
    </el-dialog>
    <!-- 修改信息 -->

    <el-dialog v-model="dialogVisibleupdateMessage" title="修改信息 " width="800px">
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">所属机构 :</div>

        <el-input
          v-model="addInput"
          style="max-width: 300px; margin-left: 10px"
          placeholder="所属机构"
          disabled
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container" >姓名:</div>
        <el-input
          v-model="this.updateTable.name"
          style="max-width: 300px; margin-left: 10px"
          placeholder="姓名"
          disabled
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年龄:</div>
        <el-input
          v-model="this.updateTable.age"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年龄"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">性别 :</div>
        <el-radio-group
          v-model="this.updateTable.sex"
          style="margin-left: 10px"
        >
          <el-radio :value="1">男</el-radio>
          <el-radio :value="2">女</el-radio>
        </el-radio-group>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">学校 :</div>
        <el-input
          v-model="this.updateTable.school"
          style="max-width: 300px; margin-left: 10px"
          placeholder="学校"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年级 :</div>
        <el-input
          v-model="this.updateTable.classAge"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年级"
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">手机号码 :</div>
        <el-input
          v-model="this.updateTable.phone"
          style="max-width: 300px; margin-left: 10px"
          placeholder="手机号码"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">状态 :</div>
        <el-radio-group
          v-model="this.updateTable.state"
          style="margin-left: 10px"
        >
          <el-radio :value="1">启用</el-radio>
          <el-radio :value="2">停用</el-radio>
        </el-radio-group>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="updateRest">重置</el-button>
          <el-button type="primary" @click="editStuendt"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>
   
<script >

import {
  getCompanyAllStudent,
  insertReachargeRecord,
  getAllBookName,
  classOpenDetail,
  insertIntoStudent,
  updatePassWord,
  updateStudent,
  getbookall,
  getstudentAllBookName,
  removeStudentClass
 
} from "../../utils/api";

export default {
  name: "STUDENT",
  methods: {
    handleClose(){
     this.radio=1
     this.input2=''
     this.input3=''
     this.SelectValue = "";
    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      console.log(" isMobile  === ", this.isMobile)
    },
    // 取消开通课程
    deleteClassDetail(value){
      removeStudentClass({
       phone:this.studentName,
       name:value
      })
      .then((response) => {
       console.log("REMOVE ===",response.data)
       this.studentclass = response.data

      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });


     this.studentName
    },
    // 修改信息
    editStuendt(){
      updateStudent({
        state:this.updateTable.state,
        agee:this.updateTable.age,
        phone:this.updateTable.phone,
        school:this.updateTable.school,
        classAge:this.updateTable.classAge,
        sex:this.updateTable.sex,

      })
      .then((response) => {
       console.log(response),
       this.dialogVisibleupdateMessage =false
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });

    },
    getstudentBookall(){
      getbookall({
       name:this.studentName
      })
      .then((response) => {
       console.log(response)
       this.selectbook = response.data
      //  this.handleCurrentChange(this.selectbook)
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    },
    updateRest(){
      this.dialogVisibleupdateMessage =false
    },
    // 修改密码
    submitFormUpdatePassword(){
   
    if(this.ruleFormPasword.pass == this.ruleFormPasword.checkPass){
      updatePassWord({
        state:1,
        password:this.ruleFormPasword.pass,
        phone:this.phoneNumber
      })
      .then((response) => {
       console.log(response),
       this.dialogVisibleupdatePassword =false
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    }


    },
    resetFormUpdatePassword(){
      this.ruleFormPasword ={
        pass: '',
        checkPass: '',
      }
    },
    // 修改
    updateStudentPassWordAction(index){
      console.log(this.tableData[index]);
      this.phoneNumber = this.tableData[index].phone
      this.codeNumber = this.tableData[index].code
      this.dialogVisibleupdatePassword =true
    },
    handleEdit(index) {
      console.log( this.tableData[index]);
      this.updateTable=this.tableData[index]
      this.dialogVisibleupdateMessage =true
      // this.$refs.dropdown1.handleOpen()
    },

// 新增
    addstudent() {
     
      // creatTime code=phone  
      this.dialogVisibleRaddStudent = true


    },
    insetStuendt(){

      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = now.getMonth() + 1, //得到月份
        date = now.getDate(); //得到日期
      var hour = now.getHours(); //获取当前小时数(0-23)
      var minute = now.getMinutes(); //获取当前分钟数(0-59)
      var second = now.getSeconds(); //获取当前秒数(0-59)
      var time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;

        console.log("time",time);

        var that = this;
        insertIntoStudent({
        name:this.addname,
        companyName:this.addInput,
        sex:this.addsexradio,
        school:this.addschool,
        classAge:this.addclassage,
        state: parseInt(this.addstateradio) ,
        creatTime:time,
        age:this.addage,
        phone:this.addphone
      })
        .then((response) => {
          if(response.code==400){
              alert(response.data)
          }
          that.dialogVisibleRecharge = false;
          location.reload()
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    


    },
    submitFormClass() {
      getAllBookName({
        name: this.inputOutClass,
        classFication:'',
        number:10,
        page:1
      })
        .then((response) => {
          console.log(response.data), 
          (this.tableDataClass = response.data);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

    },
    resetFormCLass() {
      this.inputOutClass = ''
      console.log(
        this.inputOutClass,
        this.SelectValueClassName,
        this.SelectValueWord,
        this.SelectClassValue
      );
      getAllBookName({
        name: this.inputOutClass,
        classFication:'',
        number:10,
        page:1
      })
        .then((response) => {
          console.log(response.data), 
          (this.tableDataClass = response.data);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });


    },
    resetForm(){
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    this.ruleForm={
        code: "",
        name: "",
        sex: "",
        type: "",
        companyName:'',
        page:1,
        number:10,
    }
    this.ruleForm.companyName = companyName
    this.getStudent(this.ruleForm)
    

    },
    //
    handleSelectionChange(value) {
      
      this.selectChange = value;
      console.log("=======",value);
      console.log("=1=",this.selectChange)
    },

    // 提交开通课程
    openClassDetail() {
      var classN = [];
      for (var i = 0; i < this.selectChange.length; i++) {
        classN.push(this.selectChange[i]["name"]);
      }

      var that = this;
      classOpenDetail({
        phone: this.studentName,
        className: classN.toString(),
      })
        .then((response) => {
          console.log(response.code);
          if (response.code == 200) {
            this.selectChange =[]
            that.dialogVisibleBook = false;
          }
        })
        .catch(function (error) {
          // 请求失败处理
          this.selectChange =[]
          console.log("error===", error);
        });
    },
    // 开通课程按钮
    openClass(index) {
      this.studentName = this.tableData[index].phone;
      getstudentAllBookName({
        phone:this.tableData[index].phone
      })
        .then((response) => {
          console.log("student === ",response.data),
          this.studentclass = response.data

        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      var that = this;
      getAllBookName({
        name:'',
        classFication:'',
        number:10,
        page:1
      })
        .then((response) => {
          console.log(response.data), 
        
          (that.tableDataClass = response.data);
          this.dialogVisibleBook = true;
          // this.getstudentBookall()
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
   
     
     
    },

    // 充值
    insetMoney() {
    
      this.userDetail = JSON.parse(sessionStorage.getItem("login"));
      console.log( this.radio)
      var studentAfterMoney =
        parseInt(this.student.number) + parseInt(this.input2);
      var companyAfterMoney =
        parseInt(this.userDetail[0].totalTime) - parseInt(this.input2);
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = ('0' + (now.getMonth() + 1)).slice(-2), //得到月份
        date = now.getDate(); //得到日期
      var time =
        year +
        "-" +
        month +
        "-" +
        date 
 
      var that = this;
      this.dialogVisibleRecharge = false;
      if(this.input2 !=""){
        insertReachargeRecord({
        studentphone: this.student.phone,
        companyPhone: this.userDetail[0].adminPhone,
        name: this.student.name,
        number: this.input2,
        money: this.input3,
        type: this.radio,
        moneyType: this.SelectValue,
        people: this.userDetail[0].companyName,
        time: time,
        studentBefore: this.student.number,
        studentAfter: studentAfterMoney,
        companyBefore: this.userDetail[0].totalTime,
        companyAfter: companyAfterMoney,
        companyAdmin: this.userDetail[0].adminName,
        method: "充值",
      })
        .then((response) => {
          console.log(response);
          that.input2 = "";
          that.input3 = "";
          that.radio = 1;
          that.SelectValue = "";
              // nextTick(() => {
          location.reload()
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      }else{
        alert('充值数量为空！')
      }

    },
    // 退费
    outMoney() {
      this.userDetail = JSON.parse(sessionStorage.getItem("login"));
      var studentAfterMoney =
        parseInt(this.student.number) - parseInt(this.inputOut2);
      var companyAfterMoney =
        parseInt(this.userDetail[0].totalTime) + parseInt(this.inputOut2);

      console.log("out ==== ", studentAfterMoney, companyAfterMoney);
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = now.getMonth() + 1, //得到月份
        date = now.getDate(); //得到日期
      var hour = now.getHours(); //获取当前小时数(0-23)
      var minute = now.getMinutes(); //获取当前分钟数(0-59)
      var second = now.getSeconds(); //获取当前秒数(0-59)
      var time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      var that = this;
      if (this.inputOut2!=""){
        insertReachargeRecord({
        studentphone: this.student.phone,
        companyPhone: this.userDetail[0].adminPhone,
        name: this.userDetail[0].adminName,
        number: this.inputOut2,
        money: 0,
        type: this.radioOut,
        moneyType: "线上",
        people: this.student.name,
        time: time,
        companyBefore: this.student.number,
        companyAfter: studentAfterMoney,
        studentBefore: this.userDetail[0].totalTime,
        studentAfter: companyAfterMoney,
        companyAdmin: this.userDetail[0].adminName,
        method: "退费",
      })
        .then((response) => {
          console.log(response);
          that.inputOut2 = "";
          that.input3 = "";
          that.radioOut = 1;
          that.SelectValue = "";
          that.dialogVisibleRechargeOut = false;
              // nextTick(() => {
          location.reload()
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      }else{
        alert("退费数量为空！")
      }


    },
    handleInputtuifei(){

      var threeTime =  this.student.thirtyClass
      var totalTime =  this.student.number
      var trialClassTime =  this.student.trialClass
      var sixTime =  this.student.sixClass
     if(parseInt(this.inputOut2)>parseInt(threeTime)&&this.radioOut==1){
       alert("30分钟课程类型数量不足请联系管理员！")
      this.inputOut2 = 0
     }else
     if(parseInt(this.inputOut2)>parseInt(sixTime)&&this.radioOut==2){
       alert("60分钟课程类型数量不足请联系管理员！")
      
      this.inputOut2 = 0
     }else
     if(parseInt(this.inputOut2)>parseInt(trialClassTime)&&this.radioOut==3){
       alert("体验课课程类型数量不足请联系管理员！")
      this.inputOut2 = 0
     }else
     if(parseInt(this.inputOut2)>parseInt(totalTime)&&this.radioOut==4){
       alert("陪练时长数量不足请联系管理员！")
      this.input2 = 0
     }


    },
    handleInput2(value) {
      // 正则表达式判断正整数
      const pattern = /^[1-9]\d*$/g;

      if (pattern.test(value)) {
        // 双向绑定
        this.input3 = value;
      } else {
        // 输入值不为正整数，则从中提取出数字并拼接为字符串，赋值给model.time
        const num = value.match(/\d+/g);
        this.input3 = num ? num.join("") : "";
      }
      this.input3Chinsese = this.toChies(this.input3);
      console.log(this.toChies(this.input2));
    },
    handleInput(value) {
      var threeTime = JSON.parse(sessionStorage.getItem("login"))[0]['threeTime']
      var totalTime = JSON.parse(sessionStorage.getItem("login"))[0]['totalTime']
      var trialClassTime = JSON.parse(sessionStorage.getItem("login"))[0]['trialClassTime']
      var sixTime = JSON.parse(sessionStorage.getItem("login"))[0]['sixTime']
     if(parseInt(this.input2)>parseInt(threeTime)&&this.radio==1){
       alert("30分钟课程类型数量不足请联系管理员！")
      this.input2 = 0
     }else
     if(parseInt(this.input2)>parseInt(sixTime)&&this.radio==2){
       alert("60分钟课程类型数量不足请联系管理员！")
      
      this.input2 = 0
     }else
     if(parseInt(this.input2)>parseInt(trialClassTime)&&this.radio==3){
       alert("体验课课程类型数量不足请联系管理员！")
      this.input2 = 0
     }else
     if(parseInt(this.input2)>parseInt(totalTime)&&this.radio==4){
       alert("陪练时长数量不足请联系管理员！")
      this.input2 = 0
     }
     else{
            // 正则表达式判断正整数
            const pattern = /^[1-9]\d*$/g;

            if (pattern.test(value)) {
              // 双向绑定
              this.input2 = value;
            } else {
              // 输入值不为正整数，则从中提取出数字并拼接为字符串，赋值给model.time
              const num = value.match(/\d+/g);
              this.input2 = num ? num.join("") : "";
            }
            this.input2Chinsese = this.toChies(this.input2);
                }
   

    },

    toChies(values) {
      //形参
      let len = values.length; //统计出长度
      let arr = [];

      let chin_list = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
      ]; //所有的数值对应的汉字

      let chin_lisp = [
        "仟",
        "佰",
        "拾",
        "亿",
        "仟",
        "佰",
        "拾",
        "万",
        "仟",
        "佰",
        "拾",
      ]; //进制

      for (let i = 0; i < len; i++) {
        arr.push(parseInt(values[i])); //输入的数据按下标存进去   存进去的只是数字
        arr[i] = chin_list[arr[i]]; //是根据我们输入的输入的数字，对应着我们的chin_list这个数组
      } //123['壹','佰','贰','拾','叁']

      for (let i = len - 1, j = 1; i > 0; i--) {
        //i =2  1       //倒序        为了添加进制，方便我们去观看
        arr.splice(i, 0, chin_lisp[chin_lisp.length - j++]); //j=2
      }
      console.log(arr);

      arr = arr.join("");
      if (len >= 1) {
        arr += "";
      }
      return arr;
    },

    radioChanege(index) {
      //   // threeTime     trialClassTime totalTime sixTime
      this.userDetail = JSON.parse(sessionStorage.getItem("login"));
      console.log( this.userDetail[0])
      if(index==1&& this.userDetail[0].threeTime!=0){
        alert("机构30分钟课程数量为零！")
      }
      if(index==2&& this.userDetail[0].sixTime==0){
        alert("机构60分钟课程数量为零！")
      }
      if(index==3&& this.userDetail[0].trialClassTime==0){
        alert("机构体验课程课程数量为零！")
      }
      if(index==4&& this.userDetail[0].totalTime==0){
        alert("机构陪练时长数量为零！")
      }


    },
    radioChanegeOut(index) {
      console.log(index);
    },
    // 未完成
    submitForm() {
   
     var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    this.ruleForm.companyName = companyName
    console.log('ruform === ',this.ruleForm);
      this.getStudent(this.ruleForm)
    },


    reachaege(index) {
     
      this.student = this.tableData[index];
      this.title = this.tableData[index].name;
      this.dialogVisibleRecharge = true;
   
    },
    outReachage(index) {
      console.log("退费 === ", this.tableData[index]);
      this.student = this.tableData[index];
      this.title = this.tableData[index].name;
      this.dialogVisibleRechargeOut = true;
    },

    handleCommand(index) {
      console.log(index);
    },
    handleSizeChange() {
   
     var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      this.ruleForm.companyName = companyName
      this.ruleForm.number =  this.pageSize4
      this.ruleForm.page =    this.currentPage4  
      this.getStudent(this.ruleForm)
      console.log(` items per page`,  this.ruleForm);
    },
    handleCurrentChange() {
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      this.ruleForm.companyName = companyName
      this.ruleForm.number =  this.pageSize4
      this.ruleForm.page =    this.currentPage4  
      this.getStudent(this.ruleForm)
      console.log(`current page:`,  this.ruleForm);
    },
    getStudent(params){
      // django 接口
 
    var that = this;
    getCompanyAllStudent(params)
      .then((response) => {
        that.tableData = response.data,
        that.studentcount = response.count
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    }
  },
  created() {
    this.checkMobile()
    var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    console.log(sessionStorage.getItem("login"))
    this.addInput = companyName;
    this.ruleForm.companyName = companyName
    this.getStudent(this.ruleForm)
  },
  data() {
    return {
      studentcount:0,
      isMobile: false,
      studentclass:[],
      updateTable:[],
      selectbook:[],
      dialogVisibleupdatePassword:false,
      dialogVisibleupdateMessage:false,
      ruleFormPasword :{
        pass: '',
        checkPass: '',
      },
      phoneNumber:'',
      codeNumber:'',


      addInput: "",
      addage: "",
      addradio: "",
      addsexradio: "",
      addschool: "",
      addclassage: "",
      addphone: "",
      addstateradio: "",
      addname:'',

      dialogVisibleRaddStudent: false,
      studentName: "",
      tableDataClass: [],
      selectChange: [],
      optionsClass: [
        {
          value: "1",
          label: "单词速记",
        },
      ],
      inputOutClass: "",
      SelectValueClassName: "",
      SelectValueWord: "",
      SelectClassValue: "",
      optionsClassName: [
        {
          value: "1",
          label: "考纲词库",
        },
        {
          value: "2",
          label: "二刷词库",
        },
      ],

      optionsWord: [
        {
          value: "1",
          label: "通用版",
        },
        {
          value: "2",
          label: "人教版",
        },
        {
          value: "3",
          label: "译林版",
        },
        {
          value: "4",
          label: "二刷词库",
        },
        {
          value: "5",
          label: "外研版",
        },
        {
          value: "6",
          label: "仁爱版",
        },
      ],

      dialogVisibleBook: false,

      inputOut2: "",
      inputOut3: "",
      SelectValue: "",
      input2: "",
      input2Chinsese: "",

      input3: "",
      input3Chinsese: "",

      selectDate: "",
      selectDate2: "",
      pageSize4: 10,
      currentPage4: 1,
      ruleForm: {
        code: "",
        name: "",
        sex: "",
        type: "",
        companyName:"",
        number:10,
        page:1
      },
      date: "",
      dialogVisibleRecharge: false,
      dialogVisibleRechargeOut: false,
      radio: 1,
      radioOut: 1,

      title: "",
      tableData: [],
      options: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "支付宝",
        },
        {
          value: "4",
          label: "银行卡",
        },
      ],
      student: [],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
  height:38.5vw
}

.colorBackgroundMobile{
  background-color: white;
  height:55vw
}
.colorBackgroundPC{
  background-color: white;
  height:38.5vw
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 20px;
}
.rechargeClass {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rechargeInput {
  display: flex;
  align-items: center;
  height: 30px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 35%;
}
.container {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100px;
  display: flex;
  height: 30px; /* 容器的高度 */
}
</style>
    