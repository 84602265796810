<template>
  
<div style="overflow: hidden;">
  <el-container style="overflow: hidden;" >
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img :src="backgroundlogo"  style="width: 70px;height:80px;margin-left: 50PX;margin-top: 10px"  alt="" />   
      </div>
      <div >
        <div class="title">
           <div > {{userDetail[0].adminName}}</div>
           <div><el-button  style="  margin-left: 35%;margin-top: 10px;margin-right: 15px" type="info" @click="logout">退出</el-button></div>
        </div>
        <div class="title" style="margin-bottom: 7px">
           <div > {{userDetail[0].companyName}}</div>
           
        </div>
        <!-- <div>{{userDetail[0].address}}</div> -->
      </div>
      
    </el-header>
    <!-- 页面主体区域 -->

    <el-container style="overflow: hidden;" >
      <!-- 侧边栏 -->
      <el-aside :width="'200px'" style="background-color: #000080;overflow: hidden;" >
        <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
        <!-- 侧边栏菜单区域    :default-active="route.path === '/' ? route.path : route.path.substring(1)"  -->
        <el-menu 
        :default-active="'/'+defaultactive" 
        active-text-color="#fff"
        background-color=" #000080"
        text-color="#fff" 
        unique-opened
        :router="true"
        >
        
        <!-- 一级菜单 -->
        <el-sub-menu :index="item.id+''" v-for="item in menulist" :key="item.id" >
          <!-- 一级菜单模板区域 -->
          <template #title>
            <el-icon>
                <component :is="item.icon"></component>
            </el-icon>
            <span>{{item.authName}}</span>
          </template>
          <!-- 二级菜单 -->
          <el-menu-item :index="'/'+subItem.url" v-for="subItem in item.children" :key="subItem.id">
          <template #title>
            <el-icon>
                <component :is="subItem.icon"></component>
            </el-icon>
            <span>{{subItem.authName}}</span>
          </template>
          </el-menu-item>
          
        </el-sub-menu>
      </el-menu>
      </el-aside>
      <!-- 右侧内容主体区域 -->
      <el-main style="overflow: hidden;">
        <!-- 路由占位符 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" style="display: inline-block; margin-left: 10px;margin-bottom: 10px;">
            <el-breadcrumb-item  v-for="(item, index) in pathList"
              :key="index">
              {{ item.name }}
            </el-breadcrumb-item>
        </el-breadcrumb>

        <router-view style="overflow: hidden;"></router-view>

      </el-main>
    </el-container>
  </el-container>
</div>
  </template>
  
  <script>
//  import { ref } from 'vue';

import { getUserImage } from '../../utils/api';
  export default {
    name: 'LOGIN',
    data () {
        
        return {
           // 左侧菜单数据对象
          menulist: [],
          userDetail:[],
         // 是否折叠
          isCollapse: false,
          icon: "setting",
          pathList: [],
          defaultactive:'',
          backgroundlogo:''

      }
    },
    watch: {
    //监听路由变化
    '$route': function () {
      let _this = this
      // console.log(1000)
      _this.pathList = []

      this.$route.matched.forEach(function (element, index) {
      
        console.info(element.meta); //当前元素的值
        // console.info(index);   //当前下标
        //第一个地址(index)不存，其余存入
        if (index == 0 ) {
          _this.pathList.push({ name: 'A+', path: '/home' })
        }
        if (index != 0 && element.name != 'A+') {
          _this.pathList.push({ name: element.meta, path: element.path })
        }
       
      });
      
    }
  },
  
  created (){
      this.getMenuList()
      this.userDetail = JSON.parse(sessionStorage.getItem("login")) 
      
      this.defaultactive =  this.$route.path.substring(1)

      getUserImage()
        .then((response) => {
            // 
            for(var i=0;i<response.data.length;i++)
            {
              // console.log(response.data[i]['logo'])
              if(response.data[i]['logo']){

                this.backgroundlogo= 'https://ajyyxlxt.com'+response.data[i]['logo']
                console.log(' this.backgroundlogo===', this.backgroundlogo)
              }
            }
        }
      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    


  },
   methods: {
    logout () {
       sessionStorage.clear()
      this.$router.push('/')
    },
    // 获取所有的菜单数据
    getMenuList () {
      
      this.menulist = [

      {id:1,authName:'系统首页',icon: "setting",children:[{id:1,authName:'系统首页',url:'home',icon: "setting"}]},
      {id:2,authName:'信息中心',icon:"Notification",children:[{id:1,authName:'通知公告',url:'notice',icon:"Notification"}]},
      {id:3,authName:'预约管理',icon:"CirclePlus",children:[{id:1,authName:'创建预约',url:'management',icon:"CirclePlus"}]},
      {id:4,authName:'记录管理',icon:"Grid",children:[{id:1,authName:'练习记录',url:'test',icon:"Grid"},
                                         {id:2,authName:'测试记录',url:'exercise',icon:"Tickets"},
                                         {id:3,authName:'充值记录',url:'money',icon:"Memo"},
                                         {id:4,authName:'抗遗忘记录',url:'history',icon:"Memo"}]},
      {id:5,authName:'用户管理',icon:"Service",children:[{id:1,authName:'用户列表',url:'student',icon:"User"},
                                         {id:2,authName:'陪练管理',url:'teacher',icon:"Service"},
                                         {id:3,authName:'机构管理',url:'company',icon:"Avatar"}]},
      {id:6,authName:'教学资料',icon:"Tickets",children:[{id:1,authName:'课程管理',url:'book',icon:"Memo"}]},
      {id:7,authName:'佣金管理',icon:"Money",children:[{id:1,authName:'机构佣金明细',url:'moneymanagement',icon:"Money"}]},
      {id:8,authName:'申请管理',icon:"Discount",children:[
                                           {id:1,authName:'开户申请',url:'sell',icon:"FolderOpened"}
      ]},
     
    ]
                      
                    
      console.log(this.menulist[0].authName)
    },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    }

            
  },
  mounted(){

    for (var i=0;i<this.$route.matched.length;i++)
      {
        if (i == 0 ) {
          this.pathList.push({ name: 'A+', path: '/home' })
        }
        if (i != 0 ) {
          this.pathList.push({ name: this.$route.matched[i].meta, path: this.$route.matched[i].path })
        }
       
      }

  }
      
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->

  <style scoped>
  .home_container {
    overflow: hidden;
  }
 
  .el-header {
    background-color: #1E4973;

    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color: #fff;
    font-size: 20px;

  }

  .title {

      display: flex;
      align-items: center;
      font-size: 15px;
     
    } 
  .spanclass {
        margin-left: 15px;
      }
  .el-aside {
    background-color: #313743;
   
  }
  .el-menu {
      border-right: none;
    }
  .el-main {
    background-color: #e9edf1;
  }
  .iconfont{
    margin-right: 10px;
  }
  .toggle-button{
  
   background-color: #4A5064;
 
   font-size:10px;

   line-height:24px;
   /* // 设置文本颜色 */
   color:#fff;
   /* // 设置文本居中 */
   text-align: center;
   /* // 设置文本间距 */
   letter-spacing: 0.2em;
   /* // 设置鼠标悬浮变小手效果 */
   cursor:pointer;
  }
  
  .el-menu-item.is-active {
  background-color: #ff6600!important;
}

  </style>
  