<template>
    <div >
        CLASS
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'CLASS',
   
    data () {
        
        return {
          multipleSelection: [],
          currentRow:[]
          
        }
    },
     methods: {
        
      }
      
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
   .el-header {
      background-color: #B3C0D1;
      color: #333;
      line-height: 60px;
    }
    
    .el-aside {
      color: #333;
    }
  </style>
  