<template>
  <div class="colorBackground">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="type">
        <el-input
          placeholder="充值类型"
          v-model="ruleForm.type"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="name">
        <el-input
          placeholder="转入方"
          v-model="ruleForm.name"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="people">
        <el-input
          placeholder="转出方"
          v-model="ruleForm.people"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" >
        <el-date-picker
          v-model="startTime"
          type="date"
          placeholder="充值日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </el-form-item>

    

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          查询 
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <div style="color: red;padding: 5px">
     【充值记录】所有查询条件为精确查询，不得有错别字。
    </div> -->

    <el-table :data="searchRecord"  style="width: 100%;height: 27vw;">
      <el-table-column fixed  width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        property="time"
        label="充值时间"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="type"
        width="100"
        label="充值类型"
        show-overflow-tooltip
      />
      <el-table-column
        width="100"
        property="number"
        label="充值数量"
        show-overflow-tooltip
      />
      <el-table-column
        property="money"
        width="100"
        label="金额"
        show-overflow-tooltip
      />
      <el-table-column
        width="100"
        property="people"
        label="售出方"
        show-overflow-tooltip
      />
      
      <el-table-column
        property="studentBefore"
        label="充值前"
        width="100"
        show-overflow-tooltip
      />
      <el-table-column
        property="studentAfter"
        label="充值后"
        width="100"
        show-overflow-tooltip
      />
      <el-table-column
        property="name"
        width="100"
        label="购入方"
        show-overflow-tooltip
      />
      <el-table-column
        property="companyBefore"
        label="充值前"
        width="100"
        show-overflow-tooltip
      />
      <el-table-column
        property="companyAfter"
        width="100"
        label="充值后"
        show-overflow-tooltip
      />
      <el-table-column
        property="moneyType"
        label="付款方式"
        width="100"
        show-overflow-tooltip
      />
      <!-- <el-table-column
        property="type"
        label="充值方式"
        width="100"
        show-overflow-tooltip
      /> -->
      <el-table-column
        property="method"
        label="方式"
        width="100"
      />
      <el-table-column
        property="companyAdmin"
        label="操作者"
        width="100"
        show-overflow-tooltip
      />
    </el-table>


      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="height: 45px;padding-left: 10px;"
      />
  
  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'
import { getReachargeRecord } from "../../utils/api";
export default {
  name: "MONEYLIST",
  methods: {

   getData(number,page){

    var that = this
    getReachargeRecord({ 
      name:this.ruleForm.name,
      people:this.ruleForm.people,
      method:this.ruleForm.type,
      time:this.startTime,
      number:number,
      companyName:this.companyname,
      page:page })
      .then((response) => {
        console.log(response)
        that.searchRecord = response.data
        this.count = response.count
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
   },

    submitForm() {

      this.getData(10,1)

    },

    resetForm() {
      this.ruleForm = {
        type: "",
        name: "",
        people:""
      };
     
        this.startTime =''
        this.getData(10,1)
    },
    creatByteacher() {
      this.dialogVisibleteacher = true;
    },
    creatByTime() {
      this.dialogVisible = true;
    },
    handleEdit(index) {
      console.log(index);
    },
    handleDelete(index) {
      console.log(index);
    },
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.getData(this.pageSize4, this.currentPage4)
    },
    handleCurrentChange() {
      console.log(`current page:`, this.pageSize4, this.currentPage4);
      this.getData(this.pageSize4, this.currentPage4)
    },
  },
  created() {
    this.companyname = JSON.parse(sessionStorage.getItem("login"))[0][
        "companyName"
      ];
        this.getData(10,1)
      



  },
  data() {
    return {
      count:0,
      companyname:'',
      selectDate: "",
      selectDate2: "",
      searchRecord: [],
      date: "",
      dialogVisibleteacher: false,
      selectClassValue: "",
      startTime: "",
      endTime: "",
      pageSize4: 10,
      currentPage4: 1,
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        type: "",
        name: "",
        people: ""
      },
      tableData: [],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
      ],
      TeccherTableData: [
        
      ],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
  height:36vw;
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 10px;
}
.inputclass {
  margin-left: 20px;
  width: 150px;
}
.paginationClass {
  position: fixed;
  bottom: 10px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 30%;
}
</style>
    