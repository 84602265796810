<template>
  <div class="colorBackground">
    <el-button class="header" type="primary" @click="submitForm">
      开户申请
    </el-button>

    <el-table
      :data="tableData"
      style="width: 100%; height: 30vw; overflow-y: auto"
      @cell-click="handleClick"
    >
      <el-table-column fixed width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        property="companyName"
        label="机构名称"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="adminName"
        label="申请人姓名"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="adminPhone"
        label="申请人账号"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="companyName"
        width="200px"
        label="申请人机构名称"
        
        show-overflow-tooltip
      />
      <el-table-column label="初审意见" show-overflow-tooltip>
        <template #default="scope">{{
          scope.row.state == '默认' ? "待审核" : "审核通过"
        }}</template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">{{
          scope.row.state == '默认' ? "未通过" : "已通过"
        }}</template>
      </el-table-column>
      <el-table-column
        property="auditTime"
        label="审核时间"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="creatTime"
        label="创建时间"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        property="upCompanyName"
        label="操作"
        show-overflow-tooltip
      />
    </el-table>

      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="background-color: white;height: 45px;padding-left: 10px;"
      />

    <el-dialog v-model="dialogVisible" :title="title" width="800">
      <el-form>
        <div  class="container">
          <el-form-item
        
            prop="name"
            label="机构名称："
            :rules="[
              {
                required: true,
              },
            ]"
          >
            <el-input
              placeholder="机构名称"
              v-model="ruleForm.name"
              autocomplete="off"
              style="width:280px"
            />
          </el-form-item>
          <el-form-item
            prop="companyUserName"
            label="机构实名"
            :rules="[
              {
                required: true,
              },
            ]"
          >
            <el-input
              placeholder="机构实名"
              v-model="ruleForm.companyUserName"
              autocomplete="off"
              style="width:280px"
            />
          </el-form-item>
        </div>
        <div class="container">
          <el-form-item
            prop="upCompanyName"
            label="机构归属："
            :rules="[
              {
                required: true,
              },
            ]"
          >
            <el-input
              placeholder="机构归属"
              v-model="ruleForm.upCompanyName"
              disabled
              autocomplete="off"
              style="width:280px"
            />
          </el-form-item>
          <el-form-item
            prop="payCenter"
            label="交付中心名称："
            :rules="[
              {
                required: true,
              },
            ]"
          >
            <el-input
              placeholder="交付中心名称"
              v-model="ruleForm.payCenter"
              autocomplete="off"
              style="width:280px"
            />
          </el-form-item>
        </div>
        <div class="container">
          <el-form-item
          prop="serviceCenter"
          label="机构类型"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="机构类型"
            v-model="ruleForm.serviceCenter"
            autocomplete="off"
          />
        </el-form-item>
      
        <el-form-item
          prop="money"
          label="收费金额"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="机构类型"
            v-model="ruleForm.money"
            autocomplete="off"
          />
        </el-form-item>
        
        <el-form-item
          prop="companywhere"
          label="机构来源"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="机构类型"
            v-model="ruleForm.companywhere"
            autocomplete="off"
          />
        </el-form-item>
        </div>
        <div class="container">
          <el-form-item
          prop="sixTime"  
          label="60分钟软件时长："
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="60分钟软件时长"
            v-model="ruleForm.sixTime"
            disabled
            autocomplete="off"
            style="width:280px"
          />
        </el-form-item>
        <el-form-item
          prop="threeTime"  
          label="30分钟软件时长"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="30分钟软件时长"
            v-model="ruleForm.threeTime"
            disabled
            autocomplete="off"
            style="width:280px"
          />
        </el-form-item>
        </div>
        <div class="container">
          <el-form-item  
          prop="userName"
          label="负责人姓名"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="负责人姓名"
            v-model="ruleForm.userName"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          prop="uerCode"
          label="负责人身份证"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="负责人身份证"
            v-model="ruleForm.uerCode"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          prop="userPhone"
          label="负责人手机"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="负责人手机"
            v-model="ruleForm.userPhone"
            autocomplete="off"
          />
        </el-form-item>
   

        </div>
        <div class="container">
          <el-form-item   
          prop="adminName"
          label="管理员姓名"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="管理员姓名"
            v-model="ruleForm.adminName"
            autocomplete="off"
          />
        </el-form-item>
       
        <el-form-item
          prop="adminCode"  
          label="管理员身份证"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="管理员身份证"
            v-model="ruleForm.adminCode"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          prop="adminPhone"
          label="管理员手机"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="管理员手机"
            v-model="ruleForm.adminPhone"
            autocomplete="off"
          />
        </el-form-item>

        </div>
      
        <el-form-item
          prop="adminAddress"  
          label="机构详细地址"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="机构详细地址"
            v-model="ruleForm.adminAddress"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item  
          prop="people"
          label="推荐人姓名"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <el-input
            placeholder="推荐人姓名"
            v-model="ruleForm.people"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="addCompany">
            申请提交
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
   
    <script>
//  

import { searchCompanyList,insertIntoCompany } from "../../utils/api";
export default {
  name: "SELLIST",
  methods: {
    submitForm() {
      this.dialogVisible = true;
    },
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);

      this.getCompanyList(this.currentPage4, this.pageSize4);
    },
    handleCurrentChange() {
      console.log(`current page:`, this.pageSize4, this.currentPage4);
      this.getCompanyList(this.currentPage4, this.pageSize4);
    },
    addCompany(){
      console.log(this.ruleForm)
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = ('0' + (now.getMonth() + 1)).slice(-2), //得到月份
        date = now.getDate(); //得到日期
      this.ruleForm.creatTime =  year +  "-" + month +  "-" + date 
      const min = 100000;
      const max = 999999;
      var  randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      this.ruleForm.companyCode = "DL"+ randomNumber
      this.ruleForm.upCompanyName =  JSON.parse(sessionStorage.getItem("login"))[0][
        "companyCode"
      ];
      this.dialogVisible = false;
      insertIntoCompany(this.ruleForm)
        .then((response) => {
           console.log(response)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    getCompanyList(number,page) {
      this.company = JSON.parse(sessionStorage.getItem("login"))[0][
        "companyName"
      ];

      var upcompanyName = JSON.parse(sessionStorage.getItem("login"))[0][
        "companyCode"
      ];
      searchCompanyList({
        companyName: this.company,
        upCompanyName: upcompanyName,
        number:number,
        page:page
      })
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].state == '默认') {
              this.tableData.push(response.data[i]);
            }
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    handleClick(row) {
      this.dialogVisible = true;
      this.message = row.message;
      this.title = row.title;
      console.log(row.message);
    },
  },
  data() {
    return {
      currentPage4: 1,
      pageSize4: 1,
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        name: "",
        upCompanyName:'',
        companyUserName:'',
        companyCode:'',
        payCenter:'',
        people:'',
        serviceCenter :'',
        money :'',
        companywhere:'',
        sixTime :0,
        threeTime :0,
        userName:'', 
        uerCode :'', 
        userPhone:'',
        adminName :'',
        adminCode :'',
        adminAddress:'',
        adminPhone:'',
        creatTime:''

      },
      tableData: [],
    };
  },
  created() {
    this.getCompanyList(10,1);
    this.ruleForm.upCompanyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
   height:38vw
}
.el-row {
  position: relative;
  margin-left: 10px;
  margin-top: -10px;
  padding-top: 5px;
}
.header {
  margin-left: 10px;
  margin-bottom: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 30%;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

</style>
    