<template>
  <title>A+系统</title>
   <router-view></router-view>
   
</template>

<script>



export default {
  name: 'App',
  data() {
    return {
    
    };
  },
  created() {
    
  },
  methods: {
   
   
  }
}
</script>

<style>

</style>
