<template>
  <div class="colorBackground">
    <div id="app">
    <div class="topBar">
    </div>
    <div class="title">
        <h4>英语水平测试专业报告</h4>
		<span>测试时间:{{result.createTime}}</span>
        <button onclick="window.print();" class="noprint printButton">打印</button>

    </div>
    <div class="box personal-info">
        <div class="left">
            <h2>学生信息</h2>
            <h3>姓名:{{result.username}}</h3>
            <h3>性别:{{result.sexText}}</h3>
			<h3>年级:{{result.tryInfoGrade}}</h3>
        </div>
        <div class="right">
            <span><strong>{{result.username}}</strong>你好 你的词汇量为：</span>
            <h1>{{result.vocabulary}}</h1>
            <span>相当于<strong style="color:red;">{{result.ability}}</strong></span>
        </div>
    </div>
    <div class="box">
        <div class="testBg">
            <div class="column"></div>
        </div>
    </div>
    <div class="box">
        <h2>语言能力</h2>
        <p>{{result.description}}</p >
        <h2>测评分析</h2>
        <p>{{result.evaluation}}</p >
        <h2>学习建议</h2>
        <p>{{result.suggestion}}</p >
    </div>
</div>
  </div>
</template>
     
      <script>
import { getwordtestnumberdetail } from "../../utils/api";
export default {
  name: "VOCABULARY",
  methods: {
    deleteRow(index) {
      console.log(index);
    },
  },
  created() {
    console.log(this.$route.query)
    getwordtestnumberdetail({username:this.$route.query.username,userId:this.$route.query.userID})
        .then((response) => {
          console.log(response)
         this.result = response.data[0].result
         this.result.description = response.data[0].ability.description
         this.result.evaluation = response.data[0].ability.evaluation
         this.result.suggestion = response.data[0].ability.suggestion
          console.log(response)
      
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      
  },
  data() {
    return {
      selectDate: "",
      result:{
       createTime:'',
       
       username:'',
       sexText:'',
       tryInfoGrade:'',
       ability:'',
       vocabulary:'',
       description:'',
       evaluation:'',
       suggestion:''


      }
    };
  },
};
</script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
body {
  padding: 0;
  margin: 0;
  background: #eaeaea;
}

p {
  margin-top: 20px;
}

.topBar {
  /* background-image: url("./image/vocabulary_topbg.png"); */
  width: 100%;
  height: 100px;
  display: block;
}

.title {
  width: 100%;
  height: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.title h2 {
  font-size: 36px;
  height: 60px;
}

.title span {
  height: 30px;
  line-height: 30px;
}

.box {
  width: 700px;
  margin: 0 auto;
  border-radius: 12px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.personal-info {
  height: 200px;
}

.personal-info .left {
  height: 100%;
  width: 45%;
  float: left;
  margin-right: 5%;
}

.personal-info .right {
  height: 100%;
  height: 100%;
  width: 50%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  border-left: 4px solid #35aed8;
  font-size: 20px;
  padding-left: 5px;
  font-weight: 700;
  text-align: left;
  height: 20px;
  line-height: 20px;
  margin: 18px;
}

h3 {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  margin-top: 7px;
  margin-left: 18px;
  border-bottom: 1px solid #ccc;
}

h1 {
  margin: 10px;
}

h4 {
  font-size: 32px;
}

p {
  font-size: 14px;
  width: 95%;
  line-height: 1.8em;
  text-align: justify;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.testBg {
  background-size: 100% 100%;
  background-image: url("https://ajyyxlxt.com/media/img/vocabulary_COwK6l7.png");
  height: 280px;
  margin: 20px;
  position: relative;
  border: 0px solid red;
}

.column {
  background: #35aed8;
  width: 53px;
  position: absolute;
  left: 550px;
  bottom: 57px;
}

.printButton {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  background: #ff9900;
  color: #fff;
  font-size: 12px;
  float: right;
  border: 1px solid #ccc;
  margin-left: 20px;
}

@media print {
  .noprint {
    display: none;
  }
}
</style>
      