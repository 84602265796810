<template>
    <div class="colorBackground">
    
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="auto"
        class="el-row"
      >
        <el-form-item label="学生姓名" prop="title">
          <el-input   placeholder="请输入学生姓名" v-model="ruleForm.studentname" autocomplete="off" />
        </el-form-item>
        <el-form-item class="header" label="日期" prop="message">
          <el-date-picker
          v-model="ruleForm.date"
          type="date"
          placeholder="抗遗忘日期"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
        </el-form-item>
  
        <el-form-item>
          <el-button  class="header"  type="primary" @click="submitForm(10,1)">
            查询
          </el-button>
          <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="scroll-behavior: smooth;">
      <el-table :data="tableData" style="width: 100%;height: 30vw;overflow-y: auto;"   @cell-click="handleClick">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="date" width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
        <el-table-column property="bookName" label="课程名"    show-overflow-tooltip />
        <el-table-column property="studentname" label="学生姓名"    show-overflow-tooltip />
        <el-table-column property="studentphone" label="学生电话"    show-overflow-tooltip />
        <el-table-column property="teachername" label="教师姓名"    show-overflow-tooltip />
   
        <el-table-column property="teacherphone" label="教师电话"    show-overflow-tooltip />

        <el-table-column property="dateTime" label="日期"    show-overflow-tooltip />
      </el-table>
      <el-pagination
          v-model:current-page="currentPage4"
          v-model:page-size="pageSize4"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          style="background-color: white;height: 45px;padding-left: 10px;"
        />
  
     
    </div>
  
    </div>
  </template>
     
      <script>
  //  import { reactive, ref } from 'vue'
  
  import {gethistoryListDeatailclass } from "../../utils/api";
  export default {
    name: "HISTORY",
    methods: {
      submitForm(number, page) {
        console.log(this.ruleForm)
        var studentname = this.ruleForm.studentname
        var date =  this.ruleForm.date
        var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
        "companyName"
      ];
        gethistoryListDeatailclass({companyName:companyName, number: number,
          page: page,studentname:studentname,date:date})
        .then((response) => {
            if (response.code !=400){
               this.tableData = response.data
               this.count = response.count
               console.log(response)
            }
          })
  
      },
  
      handleSizeChange() {
        console.log(` items per page`, this.pageSize4, this.currentPage4);
        this.submitForm( this.pageSize4,this.currentPage4,this.ruleForm.studentname,this.ruleForm.date);
      },
      handleCurrentChange() {
        console.log(`current page:`, this.pageSize4, this.currentPage4);
        this.submitForm(this.pageSize4,this.currentPage4, this.ruleForm.studentname,this.ruleForm.date);
      },
  
      resetForm() {
        this.ruleForm = {
          title: "",
          message: "",
        };
        this.submitForm(10,1, this.ruleForm.title,this.ruleForm.message)
      },
     
      handleClick(row){
        this.dialogVisible = true
        this.message = row.message
        this.title = row.title
        console.log(row.message)
      }
      },
    data() {
      return {
        count:0,
        currentPage4: 1,
        pageSize4: 10,
        dialogVisible: false,
        title: "",
        message: "",
        ruleForm: {
          studentname: "",
          date: "",
        },
        tableData: [
         
        ],
        conut:0,

      };
    },
    created(){
      this.submitForm(10,1,this.ruleForm.studentname,this.ruleForm.date)
    }
  };
  </script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
  .colorBackground {
   
     height:38vw
  }
  .el-row {
    position: relative;
    margin-left:10px;
    padding-top:5px;
  
  }
  .header{
  
    margin-left:10px;
  
  }
  .paginationClass {
      position: fixed;
      bottom: 0px;
      height: 40px;
      width: 100%;
      text-align: center;
      margin-left: 30%;
  }
  </style>
      