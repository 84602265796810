<template>
    <div class="colorBackground">
      陪练时长-购入记录
      
    </div>
  </template>
    
    <script>
  //  import { reactive, ref } from 'vue'
  
  
    export default {
      name: 'BUYLIST',
      methods: {
        toDetail() {
          const param1 = 'Hello';
          const param2 = 'World';
          this.$router.push({ path: '/class', state: { param1, param2 } });
        },
        onSubmit(){
          this.dialogFormVisible = false 
          console.log(this.form)
        }
      },
      data () {
          
          return {
            dialogTableVisible :false,
            dialogFormVisible :false,
            formLabelWidth:'140px',
  
            form :{
              name: '',
              region: '',
              date1: '',
              date2: '',
              delivery: false,
              type: [],
              resource: '',
              desc: '',
            },
  
           gridData : [
              {
                date: '2016-05-02',
                name: 'John Smith',
                address: 'No.1518,  Jinshajiang Road, Putuo District',
              },
              {
                date: '2016-05-04',
                name: 'John Smith',
                address: 'No.1518,  Jinshajiang Road, Putuo District',
              },
              {
                date: '2016-05-01',
                name: 'John Smith',
                address: 'No.1518,  Jinshajiang Road, Putuo District',
              },
              {
                date: '2016-05-03',
                name: 'John Smith',
                address: 'No.1518,  Jinshajiang Road, Putuo District',
              },
            ]
  
          }
        }
     
     
        
      
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
  .colorBackground {
    background-color: bisque;
     height:38vw
  }
  </style>
    