<template>
  <div class="container" :style="{ backgroundImage: 'url(' + backgroundUrl + ')' }">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      label-width="auto"
    >
      <el-form-item   label="用户名:" >
        <el-input
          v-model="ruleForm.userName"
          autocomplete="off"
          style="width:15vw"
        />
      </el-form-item>

      <el-form-item label="密码:" prop="pass">
        <el-input
          v-model="ruleForm.password"
          type="password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="buttonClass" @click="toDetail"> 登录 </el-button>
      </el-form-item>
    
    </el-form>
   


  </div>

      <a href="https://beian.miit.gov.cn/"  style="margin-left: 50vw" target="_blank">辽ICP备2024033875号-3</a >
  
</template>
    
    <script>
//  import { reactive, ref } from 'vue'

import { getCompany,getUserImage } from "../../utils/api";
export default {
  name: "FIRST",
  methods: {
    toDetail() {
      var that = this
      getCompany({
        phone: this.ruleForm.userName,
        password: this.ruleForm.password,
      })
        .then((response) => {
          if (response.code !=400){
            that.$router.push({ path: '/home'}),
            
            sessionStorage.setItem("login", JSON.stringify(response.data) )
            sessionStorage.setItem("upcompany", JSON.stringify(response.upcompany) )
            sessionStorage.setItem("teacher", JSON.stringify(response.teacher) )
          }else{
            alert(response.data )
            console.log(response)
          }
        }

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
          alert("用户名密码错误！")
        });
      // 
    },
    resetForm() {
      this.ruleForm = {};
    },
    onSubmit() {
      this.dialogFormVisible = false;
      console.log(this.form);
    },
  },
  data() {
    return {
      backgroundUrl:'',
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "140px",

      ruleForm: {
        password: "",
        userName: "",
      },
    };
  },
  created() {
    // django 接口 getUserImage
    getUserImage()
        .then((response) => {
            // 
            for(var i=0;i<response.data.length;i++)
            {
              if(response.data[i]['background']){
                this.backgroundUrl= 'https://ajyyxlxt.com'+response.data[i]['background']
              }
            }
        }
      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: bisque;
  height: 500px;
  overflow: hidden;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 使用视窗高度来保证容器高度 */
  /* background: url("https://ajyyxlxt.com/media/img/background_1wIbv1X.png"); */
  background-size: cover;
}
.buttonClass{
  color: #000;
}
::v-deep .el-form-item__label{
  color:black;
}
</style>
    