<template>
  <div >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="code">
        <el-input
          placeholder="机构别名"
          v-model="ruleForm.code"
          autocomplete="off"
        />
      </el-form-item>
      
      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(10,1)">
          查询 
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
    <el-button style="margin-bottom: 10px;margin-left: 20px"  type="primary" @click="reachaege"> 提交 </el-button>
  </div>
  </div>

  <el-table :data="gridData"  style="height: 25vw;">
      <el-table-column   fixed  width="100" label="序号">
      <template #default="scope"  >{{  scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column property="rechargeCompanyName" label="机构名称" width="200" />
      <el-table-column property="type" label="退费类型"  width="200" />
      <el-table-column property="number" label="退费数量" width="250" />
      <el-table-column property="state" label="状态" width="200" >
       
      <template #default="scope"  >{{scope.row.state=='0'?"待审核":"已完成"}}</template>

      </el-table-column>
      <el-table-column property="message" label="备注" width="200" />
      <el-table-column property="creatPeople" label="操作者" width="200" />
      <el-table-column property="creatTime" label="创建时间" width="200" />
      <el-table-column    width="100" label="操作">
      <template #default="scope"  >
        <el-button size="small"  v-if="scope.row.ToCompanyReachargeName == company&&scope.row.state=='0'?true:false"  type="danger" @click="handleEdit(scope.$index, scope.row)">
            同意
          </el-button>
      </template>
      </el-table-column>

    </el-table>


    <el-pagination
      v-model:current-page="currentPage4"
      v-model:page-size="pageSize4"
      layout="total, sizes, prev, pager, next, jumper"
      :total="numberReachage"
      @size-change="handleSizeChange"
      style="background-color: white;padding-left: 10px;height: 45px;"
      @current-change="handleCurrentChange"
    />

  <!-- 退费 -->
  <el-dialog v-model="dialogVisibleRecharge" title="退费申请" width="800px">
    
    <div>
      <span>机构名称 ：</span>
      <el-input
   
        style="max-width: 300px; margin-left: 10px"
        maxlength="3"
        disabled
        :placeholder="company"
      >
     
      </el-input>
    </div>

    
    <div class="rechargeClass">
      退费类型:
      <el-radio-group
        v-model="radio"
        @change="radioChanege"
        style="margin-left: 10px"
      >
        <el-radio :value="1" border>60分钟</el-radio>
        <el-radio :value="2" border>体验</el-radio>
        <el-radio :value="3" border>陪练</el-radio>
      </el-radio-group>
    </div>
    <div class="rechargeInput">
      <div>退费数量 :</div>

      <el-input
        v-model="input2"
        style="max-width: 300px; margin-left: 10px"
        maxlength="3"
        @input="handleInput"
        placeholder="退费数量"
      >
     
      </el-input>
    
    </div>
    <div>
      <div class="rechargeInput" style="margin-top: 20px">
        <div style="width: 62px;">备注:</div>

        <el-input
          v-model="message"
          style="max-width: 300px; margin-left: 10px"
   
          placeholder="备注"
        >
        </el-input>
        
      </div>
    </div>


    <template #footer>
      <div class="dialog-footer">
        <el-button  @click="resetForm">重置</el-button>
        <el-button type="primary" @click="submitReachage"> 提交 </el-button>
      </div>
    </template>
  </el-dialog>

</template>
   
    <script>
    // companyReturnList
import {
  insertIntoReturn,companyReturnList,searchCompanyReturnList,updatecompanyReturn
  
  
} from "../../utils/api";
// import { ElMessage } from 'element-plus'
export default {
  name: "DELIVERY",
  methods: {
    submitForm(number,page){
      console.log('ruleForm ==== ',this.ruleForm.code)
      console.log('company==',this.company)
      searchCompanyReturnList({
        rechargeCompanyName: this.ruleForm.code,
        ToCompanyReachargeName: this.company,
        number:number,
        page:page
      })
        .then((response) => {
          this.gridData=[]
          if(this.ruleForm.code == this.company){
            this.gridData = response.data
          }else{
            // this.gridData = response.data
        
            for (var i =0;i<response.data.length;i++){
              console.log('response.data[i]',this.company,response.data[i].rechargeCompanyName)
                if(this.company != response.data[i].rechargeCompanyName){
                  this.gridData.push(response.data[i])
                }
            }
          }
          
          this.numberReachage = response.count
          console.log( response)

        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    handleEdit(index){
      console.log("ddd====",this.gridData[index]['ToCompanyReachargeName']);
     
      if(this.gridData[index]['ToCompanyReachargeName'] != 0){

      var params={
        ToCompanyReachargeName:this.gridData[index]["ToCompanyReachargeName"],
        type:this.gridData[index]['type'],
        number:this.gridData[index]['number'],
        returnID:this.gridData[index]['id'],
        rechargeCompanyName:this.gridData[index]["rechargeCompanyName"],

      }

      updatecompanyReturn(params)
        .then((response) => {
          console.log('response === ',response);
          location.reload();
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })
      }
      
      // else{
      //   ElMessage({
      //     message: '审核已完成！',
      //     type: 'success',
      //   })
      // }

    },
  //  退费提交
    submitReachage() {
      
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = ("0" + (now.getMonth() + 1)).slice(-2), //得到月份
        date = now.getDate(); //得到日期
      var creatTime = year + "-" + month + "-" + date;
      var adminName = JSON.parse(sessionStorage.getItem("login"))[0][
        "adminName"
      ];
      
       var retunType = ''
       if(this.radio=='1'){
          retunType="60分钟"
       }
       if(this.radio=='2'){
          retunType="体验课"
       }
       if(this.radio=='3'){
          retunType="陪练"
       }


      var params = {
        rechargeCompanyName: this.company,
        ToCompanyReachargeName:  this.upcompanyName,
        number: this.input2,//退费数量
        type: retunType,//退费类型
        state:0,
        message:this.message,
        creatPeople: adminName,
        creatTime: creatTime,
      };
      
      console.log("====",params)
      insertIntoReturn(params)
        .then((response) => {
          this.dialogVisibleRecharge = false,
          this.input2 = "",
          this.radio = 0,
          this.message = "";
          console.log(response);
          location.reload()
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    radioChanege(index) {
      console.log(index);
    },
    handleInput2(value) {

        this.input3 = value;
 
    },
    handleInput(value) {

        // 双向绑定
      this.input2 = value;
  
    },

    reachaege() {
      this.dialogVisibleRecharge = true;
      this.title = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    },
    reachaegeDetail(index) {
      this.dialogTableVisible = true
      this.reachagecompanyName = this.tableData[index].companyName
      this.getData(this.tableData[index].companyName,10,1)
    },
    getData( value,number,page){
     console.log(this.company,this.upcompanyName)
      companyReturnList({
        rechargeCompanyName: this.company,
        ToCompanyReachargeName:this.upcompanyName,
        number: number,
        page: page,
      })
        .then((response) => {

       
          this.gridData = response.data,
          this.numberReachage = response.count
          console.log( response)

        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    resetForm() {
      this.ruleForm.code=''
      this.getData(this.company,10,1)
    },
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.getData( this.company,this.pageSize4, this.currentPage4)
    },
    handleCurrentChange() {
      this.getData (this.company,this.pageSize4, this.currentPage4)
      console.log(`current page:`, this.pageSize4, this.currentPage4);
    },
  },
  created() {
    var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
   this.upcompanyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "upCompanyName"
    ];
    this.company = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    this.companyUserName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyUserName"
    ];
    this.getData(companyName,10,1)




  },
  data() {
    return {
      message:'',
      upcompanyName:'',
      selectCompanyValue:'',
      optionsCompany: [
       
      ],
      currentPage1:1,
      pageSize1:10,
      numberReachage:0,
      currentPageR:0,
      reachargedata:[],
      reachagecompanyName:'',
      gridData:[],
      dialogTableVisible:false,
      companyUserName: "",
      dialogVisibleRecharge: false,
      radio: 1,
      inputOut2: "",
      inputOut3: "",
      SelectValue: "",
      input2: "",
      input2Chinsese: "",
      title: "",
      input3: "",
      input3Chinsese: "",
      
      pageSize4: 10,
      currentPage4: 1,
      tableData: [],
      ruleForm: {
        code: "",
        name: "",
        sex: "",
        type: ""
     
      },
      company: "",
      options: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "支付宝",
        },
        {
          value: "4",
          label: "银行卡",
        },
      ],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
   height:38vw

}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 20px;
}
.rechargeClass {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rechargeInput {
  display: flex;
  align-items: center;
  height: 30px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 35%;
}


.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
</style>
    