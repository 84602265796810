<template>
  <div class="colorBackground">
    
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item label="课程分类" prop="title">
        <el-input v-model="ruleForm.title" autocomplete="off" />
      </el-form-item>
      <el-form-item class="header" label="课程名称" prop="message">
        <el-input v-model="ruleForm.message" autocomplete="off" />
      </el-form-item>

      <el-form-item>
        <el-button  class="header"  type="primary" @click="submitForm(ruleFormRef)">
          查询
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
    <el-table :data="tableData" style="width: 100%;height:28vw;overflow-y: auto;"   >
      <el-table-column type="selection" width="55" />
      <el-table-column fixed   width="100" label="序号" >
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column property="name" label="课程名称"    show-overflow-tooltip />
      <el-table-column property="classFication" label="分类"    show-overflow-tooltip />
      <!-- <el-table-column property="message" label="学段"    show-overflow-tooltip /> -->
      <el-table-column property="difficultyWord" label="难度"    show-overflow-tooltip />
      <el-table-column property="teacherBook" label="教材"    show-overflow-tooltip />
      <el-table-column property="allNumber" label="数量"    show-overflow-tooltip />
      <el-table-column property="creatBypeople" label="创建者"    show-overflow-tooltip />
      <el-table-column property="creatBytime" label="创建时间"    show-overflow-tooltip />
      <el-table-column property="remarkMessage" label="备注"    show-overflow-tooltip />
    </el-table>
   
    <el-pagination
      v-model:current-page="currentPage4"
      v-model:page-size="pageSize4"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="height: 45px;background-color: white;padding-left: 10px;"
    />
  </div>
   
  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'

import { getAllBookName } from "../../utils/api";
export default {
  name: "BOOK",
  methods: {
    submitForm() {

    console.log(this.ruleForm) 
    this.name =this.ruleForm.message
    this.classFication =this.ruleForm.title
    this.getAllBookNameAction(10,1)

    },
    handleSizeChange() {

      this.getAllBookNameAction(this.currentPage4,this.pageSize4)
    },
    handleCurrentChange() {

       this.getAllBookNameAction(this.currentPage4,this.pageSize4)
    },
    resetForm() {
      this.ruleForm = {
        title: "",
        message: "",
      };
      this.submitForm()
    },
    getAllBookNameAction(number,page){
      var that = this
      getAllBookName({number:number,page:page,name:this.name,classFication:this.classFication})
      .then((response) => {
      
             that.tableData = response.data,
             console.log(response)
          
        }

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

    },
   
    },
  data() {
    return {
      name:'',
      classFication:'',
      currentPage4: 1,
      pageSize4: 10,
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        title: "",
        message: "",
      },
      tableData: [
       
      ],
    };
  },
  created(){
    this.getAllBookNameAction(10,1)
  }
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
.colorBackground {
 
   height:38vw
}
.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 20px;

}
.header{

  margin-left:10px;

}
.paginationClass {
    position: fixed;
    bottom: 0px;
    height: 40px;
    width: 100%;
    text-align: center;
    margin-left: 30%;
}
</style>
    