<template>
  <div class="colorBackground">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="phone">
        <el-input
          placeholder="手机号"
          v-model="ruleForm.phone"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="name">
        <el-input
          placeholder="姓名"
          v-model="ruleForm.name"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="sex">
        <el-input
          placeholder="性别"
          v-model="ruleForm.sex"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="type">
        <el-input
          placeholder="复习方式"
          v-model="ruleForm.type"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          查询
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button   type="primary" @click="addsTeacher" style="margin-left: 20px;margin-bottom: 10px">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%; height: 26vw">
      <!-- <el-table-column type="selection" width="55" /> -->

      <el-table-column fixed prop="date" width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column width="150" property="name" label="姓名" />
      <el-table-column width="140" label="操作">
        <template #default="scope">
          <!-- 操作菜单 -->
          <el-dropdown
            size="small"
            trigger="click"
            split-button
            type="primary"
            @command="handleCommand"
          >
            操作

            <template #dropdown>
              <el-dropdown-menu>
                <!-- <el-dropdown-item command="a" @click="handleEdit(scope.$index)"
                  >修改</el-dropdown-item
                > -->
                <el-dropdown-item command="e"  @click="updateStudentPassWordAction(scope.$index)">修改密码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column width="150" property="phone" label="手机号" />
      <el-table-column
        width="150"
        property="state"
        label="考核结果"
        show-overflow-tooltip
      />
      <el-table-column width="150" property="teacherType" label="陪同方式" />
      <el-table-column width="150" property="jobStatus" label="在职情况" />
      <el-table-column width="150" property="thirtyClass" label="体验" />
      <el-table-column width="150" property="title" label="头衔" />
      <el-table-column width="200" property="companyName" label="所属机构" show-overflow-tooltip />
      <el-table-column width="150" property="age" label="年龄" />
      <el-table-column width="150" property="sex" label="性别" />
      <el-table-column width="300" property="creatTime" label="创建时间" />
    </el-table>


      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="margin-left: 20px;height: 45px;;"
      />

    <!-- 新增陪练 -->
    <el-dialog
      v-model="dialogVisibleRaddStudent"
      title="新增陪练"
      width="800px"
    >
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">姓名:</div>
        <el-input
          v-model="addteachername"
          style="max-width: 300px; margin-left: 10px"
          placeholder="姓名"
        >
        </el-input>
      </div>
     
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">陪同方式:</div>
        <el-input
          v-model="addteachertype"
          style="max-width: 300px; margin-left: 10px"
          placeholder="陪同方式"
        >
        </el-input>
      </div>
     
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">头衔:</div>
        <el-form-item>
          <el-select
            v-model="selectClassValue"
            clearable
            placeholder="头衔"
            style="width: 300px;margin-left: 10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </div>  
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年龄:</div>
        <el-input
          v-model="addage"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年龄"
        >
        </el-input>  
      </div> 
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">性别 :</div>
        <el-radio-group
          v-model="addsexradio"
          @change="radioChanege"
          style="margin-left: 10px"
        >
          <el-radio value="男">男</el-radio>
          <el-radio value="女">女</el-radio>
        </el-radio-group>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">身份证号 :</div>
        <el-input
          v-model="addidcard"  
            maxlength="18"
          style="max-width: 300px; margin-left: 10px"
          placeholder="身份证号"
        >
        </el-input>
      </div>
     
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">手机号码 :</div>
        <el-input
          v-model="addphone"
            maxlength="11"
          style="max-width: 300px; margin-left: 10px"
          placeholder="手机号码"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">状态 :</div>
        <el-radio-group
          v-model="addstateradio"
          @change="radioChanege"
          style="margin-left: 10px"
        >
          <el-radio :value="0">启用</el-radio>
          <el-radio :value="1">停用</el-radio>
        </el-radio-group>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button >重置</el-button>
          <el-button type="primary" @click="insetTeacher"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog v-model="dialogVisibleupdatePassword" title="修改密码 " width="800px">
      <el-form
    ref="ruleFormRef"
    style="max-width: 600px"
    :model="ruleFormPasword"
    status-icon
    label-width="auto"
    class="demo-ruleForm"
  >
    <el-form-item label="新密码"  prop="pass">
      <el-input v-model="ruleFormPasword.pass"  autocomplete="off" />
    </el-form-item>
    <el-form-item label="确认密码" prop="checkPass">
      <el-input
        v-model="ruleFormPasword.checkPass"
        autocomplete="off"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitFormUpdatePassword">
        提交
      </el-button>
      <el-button @click="resetFormUpdatePassword">重置</el-button>
    </el-form-item>
  </el-form>
    </el-dialog>
    <!-- 修改信息 -->
    <el-dialog v-model="dialogVisibleupdateMessage" title="修改信息 " width="800px">
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">所属机构 :</div>

        <el-input
          v-model="addInput"
          style="max-width: 300px; margin-left: 10px"
          placeholder="所属机构"
          disabled
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container" >姓名:</div>
        <el-input
          v-model="this.updateTable.name"
          style="max-width: 300px; margin-left: 10px"
          placeholder="姓名"
          disabled
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年龄:</div>
        <el-input
          v-model="this.updateTable.age"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年龄"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">性别 :</div>
        <el-radio-group
          v-model="this.updateTable.sex"
          @change="radioChanege"
          style="margin-left: 10px"
        >
          <el-radio :value="1">男</el-radio>
          <el-radio :value="2">女</el-radio>
        </el-radio-group>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">学校 :</div>
        <el-input
          v-model="this.updateTable.school"
          style="max-width: 300px; margin-left: 10px"
          placeholder="学校"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">年级 :</div>
        <el-input
          v-model="this.updateTable.classAge"
          style="max-width: 300px; margin-left: 10px"
          placeholder="年级"
        >
        </el-input>
      </div>

      <div style="display: flex; margin-bottom: 10px">
        <div class="container">手机号码 :</div>
        <el-input
          v-model="this.updateTable.phone"
          style="max-width: 300px; margin-left: 10px"
          placeholder="手机号码"
        >
        </el-input>
      </div>
      <div style="display: flex; margin-bottom: 10px">
        <div class="container">状态 :</div>
        <el-radio-group
          v-model="this.updateTable.state"
          @change="radioChanege"
          style="margin-left: 10px"
        >
          <el-radio :value="1">启用</el-radio>
          <el-radio :value="2">停用</el-radio>
        </el-radio-group>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="updateRest">重置</el-button>
          <el-button type="primary" @click="editStuendt"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>
   
    <script>
import {

  updatePassWord,
  updateStudent,
  searchTeacherList,
  insertIntoTeacher
 
} from "../../utils/api";

export default {
  name: "TEACHER",
  methods: {
    // 修改信息
    editStuendt(){
      updateStudent({
        state:this.updateTable.state,
        agee:this.updateTable.age,
        phone:this.updateTable.phone,
        school:this.updateTable.school,
        classAge:this.updateTable.classAge,
        sex:this.updateTable.sex,

      })
      .then((response) => {
       console.log(response)
     
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });

    },
    updateRest(){
   
    },
    // 修改密码
    submitFormUpdatePassword(){
   
    if(this.ruleFormPasword.pass == this.ruleFormPasword.checkPass){
      console.log(this.phoneNumber)
      updatePassWord({
        state:1,
        password:this.ruleFormPasword.pass,
        phone:this.phoneNumber
      })
      .then((response) => {
       console.log(response),
       this.dialogVisibleupdatePassword =false
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    }


    },
    resetFormUpdatePassword(){
      this.ruleFormPasword ={
        pass: '',
        checkPass: '',
      }
    },
    // 修改
    updateStudentPassWordAction(value){
      console.log( this.tableData[value].phone)
      this.phoneNumber = this.tableData[value].phone
     
     this.dialogVisibleupdatePassword = true
    },
    handleEdit(value) {
      console.log(value)
      this.teacherData.push(this.tableData[value])
      this.dialogVisibleupdateMessage = true
   
    },

// 新增
    addsTeacher() {
     
      // creatTime code=phone  
      this.dialogVisibleRaddStudent = true


    },
    // 插入陪练
    insetTeacher(){
      this.dialogVisibleRaddStudent =false
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = now.getMonth() + 1, //得到月份
        date = now.getDate(); //得到日期
      var hour = now.getHours(); //获取当前小时数(0-23)
      var minute = now.getMinutes(); //获取当前分钟数(0-59)
      var second = now.getSeconds(); //获取当前秒数(0-59)
      var time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
        var params ={
            name:this.addteachername,
            phone:this.addphone,
            sex:this.addsexradio,
            idCard:this.addidcard,
            age:this.addage,
            teacherType:this.addteachertype,
            state:parseInt(this.addstateradio),
            companyName:this.ruleForm.companyName,
            creatTime: time,
            title:this.selectClassValue

        }
      insertIntoTeacher(params)
      .then((response) => {
       console.log(response)
       alert(response.data)
       if(response.code == 200){
          location.reload()
       }

    
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    


    },
    submitFormClass() {},
    resetFormCLass() {
      console.log(
        this.inputOutClass,
        this.SelectValueClassName,
        this.SelectValueWord,
        this.SelectClassValue
      );
    },
    resetForm(){
       this.dialogVisibleRaddStudent =false
       this.ruleForm ={
        phone: "",
        name: "",
        sex: "",
        type: "",
        companyName:"",
        number:10,
        page:1
      }
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      this.ruleForm.companyName = companyName
      this.getTeacher(this.ruleForm)
    },
    //
    handleSelectionChange(value) {
      this.selectChange = value;
      console.log(value);
    },



    toChies(values) {
      //形参
      let len = values.length; //统计出长度
      let arr = [];

      let chin_list = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
      ]; //所有的数值对应的汉字

      let chin_lisp = [
        "仟",
        "佰",
        "拾",
        "亿",
        "仟",
        "佰",
        "拾",
        "万",
        "仟",
        "佰",
        "拾",
      ]; //进制

      for (let i = 0; i < len; i++) {
        arr.push(parseInt(values[i])); //输入的数据按下标存进去   存进去的只是数字
        arr[i] = chin_list[arr[i]]; //是根据我们输入的输入的数字，对应着我们的chin_list这个数组
      } //123['壹','佰','贰','拾','叁']

      for (let i = len - 1, j = 1; i > 0; i--) {
        //i =2  1       //倒序        为了添加进制，方便我们去观看
        arr.splice(i, 0, chin_lisp[chin_lisp.length - j++]); //j=2
      }
      console.log(arr);

      arr = arr.join("");
      if (len >= 1) {
        arr += "元";
      }
      return arr;
    },

    radioChanege(index) {
      console.log(index);
    },
    radioChanegeOut(index) {
      console.log(index);
    },
    // 未完成
    submitForm() {
   
     var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    this.ruleForm.companyName = companyName
    this.getTeacher(this.ruleForm)
    
      
    },


    reachaege(index) {
      console.log("充值 === ", this.tableData[index]);

      this.student = this.tableData[index];
      this.title = this.tableData[index].name;
      this.dialogVisibleRecharge = true;
    },
    outReachage(index) {
      console.log("退费 === ", this.tableData[index]);
      this.student = this.tableData[index];
      this.title = this.tableData[index].name;
      this.dialogVisibleRechargeOut = true;
    },

    handleCommand(index) {
      console.log(index);
    },
    handleSizeChange() {
   
     var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      this.ruleForm.companyName = companyName
      this.ruleForm.number =this.pageSize
      this.ruleForm.page = this.currentPage4
      this.getStudent(this.ruleForm)
      console.log(` items per page`, this.pageSize4, this.currentPage4);
    },
    handleCurrentChange() {
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      this.ruleForm.companyName = companyName
      this.ruleForm.number =this.pageSize
      this.ruleForm.page = this.currentPage4
      this.getTeacher(this.ruleForm)
      console.log(`current page:`, this.pageSize4, this.currentPage4);
    },
    getTeacher(params){

    var that = this;
    searchTeacherList(params)
      .then((response) => {
        that.tableData = response.data,
        console.log(response)
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    }
  },
  created() {
    var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
    this.addInput = companyName;
    this.ruleForm.companyName = companyName
    this.getTeacher(this.ruleForm)
  },
  data() {
    return {


      teacherData:[],
      addstateradio:1,
      updateTable:[],
      dialogVisibleupdateMessage:false,
      dialogVisibleupdatePassword:false,
      dialogVisibleRaddStudent:false,
      addteachername:'',
      addteachertype:'',
      selectClassValue:'',
      addage:'',
      addsexradio:'男',
      addidcard:'',
      addphone :'',
      pageSize4: 10,
      currentPage4: 1,
      ruleFormPasword :{
        pass: '',
        checkPass: '',
      },
      ruleForm: {
        phone: "",
        name: "",
        sex: "",
        type: "",
        companyName:"",
        number:10,
        page:1
      },
      date: "",
      dialogVisibleRecharge: false,
      dialogVisibleRechargeOut: false,
      radio: 1,
      radioOut: 1,

      title: "",
      tableData: [],
      options: [
        {
          value: "1",
          label: "钻石",
        },
        {
          value: "2",
          label: "金牌",
        },
        {
          value: "3",
          label: "银牌",
        },
        {
          value: "4",
          label: "铜牌",
        },
        {
          value: "5",
          label: "实习",
        },
      ],
      student: [],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color:white;
   height:38vw
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 20px;
}
.rechargeClass {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rechargeInput {
  display: flex;
  align-items: center;
  height: 30px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 35%;
}
.container {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100px;
  display: flex;
  height: 30px; /* 容器的高度 */
}
</style>
    