<template>
  <div class="colorBackground">
  
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" autocomplete="off" />
      </el-form-item>
      <el-form-item class="header" label="内容" prop="message">
        <el-input v-model="ruleForm.message" autocomplete="off" />
      </el-form-item>

      <el-form-item>
        <el-button  class="header"  type="primary" @click="submitForm(10,1)">
          查询
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="scroll-behavior: smooth;">
    <el-table :data="tableData" style="width: 100%;height: 30vw;overflow-y: auto;"   @cell-click="handleClick">
      <el-table-column type="selection" width="55" />
      <el-table-column label="id" property="id" show-overflow-tooltip>
       
      </el-table-column>
      <el-table-column property="title" label="标题"    show-overflow-tooltip />
      <el-table-column property="message" label="内容"    show-overflow-tooltip />
    </el-table>
    <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="conut"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="background-color: white;height: 45px;padding-left: 10px;"
      />

   
  </div>
    <el-dialog v-model="dialogVisible" :title="title" width="500">
      <span>{{ message }}</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            已读
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'

import {searchNotice } from "../../utils/api";
export default {
  name: "NOTICE",
  methods: {
    submitForm(number, page) {

      var that = this
      searchNotice({title:this.ruleForm.title,message:this.ruleForm.message, number: number,
        page: page,})
      .then((response) => {
          if (response.code !=400){
             that.tableData = response.data
             that.conut =response.count
             console.log(response)
          }
        })

    },

    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.submitForm(this.currentPage4, this.pageSize4);
    },
    handleCurrentChange() {
      console.log(`current page:`, this.pageSize4, this.currentPage4);
      this.submitForm(this.currentPage4, this.pageSize4);
    },

    resetForm() {
      this.ruleForm = {
        title: "",
        message: "",
      };
      this.submitForm(10,1)
    },
   
    handleClick(row){
      this.dialogVisible = true
      this.message = row.message
      this.title = row.title
      console.log(row.message)
    }
    },
  data() {
    return {
      currentPage4:1,
      pageSize4:1,
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        title: "",
        message: "",
      },
      tableData: [
       
      ],
      conut:0
    };
  },
  created(){
    this.submitForm(10,1)
  }
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
.colorBackground {
 
   height:38vw
}
.el-row {
  position: relative;
  margin-left:10px;
  margin-top:00px;
  padding-top:5px;

}
.header{

  margin-left:10px;

}
.paginationClass {
    position: fixed;
    bottom: 0px;
    height: 40px;
    width: 100%;
    text-align: center;
    margin-left: 30%;
}
</style>
    