<template>
  <div class="colorBackground">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="username">
        <el-input
          placeholder="用户姓名"
          v-model="ruleForm.username"
          autocomplete="off"
        />
      </el-form-item>


      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm">
          查询
        </el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
   
    <el-table
      :data="tableData"
      style="height: 26vw; "
    >
      <!-- <el-table-column type="selection" width="55" /> -->

      <el-table-column fixed   width="100" label="序号" >
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column  width="150"  property="username" label="姓名"  />
      <el-table-column  width="150" label="操作">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
            详情
          </el-button>

        </template>
      </el-table-column>
   
      <el-table-column  width="150" property="trueNumber" label="正确"  />
      <el-table-column  width="150" label="错误"  >
        <template #default="scope">{{ scope.row.falseNumber}}</template>
   
      </el-table-column>
      <el-table-column  width="150" property="ability" label="等级"  />
      <el-table-column  property="vocabulary" label="词汇量"  />
      <!-- <el-table-column  width="150" property="startime" label="测试时间"  /> -->

    </el-table>

    
      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="height: 45px;padding-left: 10px;"
      />
   

  </div>
</template>
   
    <script>

import { getwordtestnumberList } from "../../utils/api";
export default {
  name: "EXERCISELIST",
  methods: {

    handleEdit(index){
      window.open( process.env.BASE_URL+'/#/vocabulary'+'?username='+this.tableData[index]["username"]+'&userID='+this.tableData[index]["id"],'_blank');
     
     
    },
    
    handleSizeChange() {

    // this.getAllBookNameAction(this.currentPage4,this.pageSize4)
    getwordtestnumberList({username:this.ruleForm.username,userId:0,number:this.currentPage4,page:this.pageSize4,companyName:this.companyName})
        .then((response) => {
        
          this.tableData = response.all
          console.log(response.all)
      
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    handleCurrentChange() {

      getwordtestnumberList({username:this.ruleForm.username,userId:0,number:this.currentPage4,page:this.pageSize4,companyName:this.companyName})
        .then((response) => {
        
          this.tableData = response.all
          console.log(response.all)
      
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    resetForm(){
      this.tableData=[]
      this.ruleForm.username=''
      
      getwordtestnumberList({username:this.ruleForm.username,number:10,page:1,companyName:this.companyName})
        .then((response) => {
        
          this.tableData = response.all
          console.log(response.all)
      
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    submitForm() {
      this.tableData =[]
      console.log("",this.ruleForm.username)
      getwordtestnumberList({username:this.ruleForm.username,companyName:this.companyName})
        .then((response) => {
        console.log(response)
          for(var i=0;i<response.all.length;i++)
          {
            this.tableData.push(response.all[i])
          }
          

      
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      },


   
  },
  created() {
    this.companyName =   JSON.parse(sessionStorage.getItem("login"))[0][
          "companyName"
        ];
   this.resetForm()
 
   
  },
  data() {
    return {
      companyName:'',
      pageSize4:10,
      currentPage4:1,
      ruleForm: {
        username:'',
       
      },
      tableData: [
      ],

     
      }
    }
  
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
  height:35vw;
  overflow: hidden;
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 10px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 30%;
}
</style>
    