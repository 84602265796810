import { createRouter,createWebHashHistory } from "vue-router";
import CLASS from '../components/people/Class.vue'
import LOGIN from '../components/Login/Login.vue'
import NOTICE  from '../components/noticeCenter/index.vue'
import MANAGEMENT  from '../components/management/index.vue'
import SELLLIST  from '../components/listManagement/sellList.vue'
import BUYLIST  from '../components/listManagement/buyList.vue'
import EXERCISELIST  from '../components/listManagement/exerciseList.vue'
import MONEYLIST  from '../components/listManagement/moneyList.vue'
import TESTLIST  from '../components/listManagement/testlist.vue'
import COMPANY  from '../components/peopleManagement/company.vue'
import STUDENT  from '../components/peopleManagement/student.vue'
import TEACHER  from '../components/peopleManagement/teacher.vue'
import DELIVERY  from '../components/peopleManagement/delivery.vue'
import BOOK  from '../components/book/index.vue'
import MONEY  from '../components/money/index.vue'
import HOME  from '../components/views/Home.vue'
import FIRST from '../components/Login/index.vue'
import VOCABULARY from '../components/vocabulary/index.vue'
import HISTORY from '../components/listManagement/history.vue'



const routes = [
    {
      
      path: '/',
      name: 'FIRST',
      component: FIRST,
    },
    {
      
      path: '/vocabulary',
      name: 'VOCABULARY',
      component: VOCABULARY,
    },


    {
      path: '/login',
      name: 'LOGIN',
      component: LOGIN,
      children: [
        { path: '/class', component: CLASS },
        { path: '/home', component: HOME,meta:'首页' },
        { path: '/notice', component: NOTICE,meta:'通知公告' },
        { path: '/management', component: MANAGEMENT,meta:'创建预约' },
        { path: '/sell', component: SELLLIST,meta:'开户申请' },
        { path: '/buylist', component: BUYLIST },
        { path: '/exercise', component: EXERCISELIST,meta:'测试记录' },
        { path: '/money', component: MONEYLIST ,meta:'充值记录'},
        { path: '/test', component: TESTLIST,meta:'练习记录' },
        { path: '/company', component: COMPANY ,meta:'机构管理'},
        { path: '/student', component: STUDENT,meta:'用户列表' },
        { path: '/teacher', component: TEACHER ,meta:'陪练管理'},
        { path: '/delivery', component: DELIVERY,meta:'机构退费申请' },
        { path: '/book', component: BOOK ,meta:'课程管理'},
        { path: '/moneymanagement', component: MONEY ,meta:'机构佣金明细'},
        { path: '/history', component: HISTORY ,meta:'抗遗忘记录'},

      ]
  
    }
      

  
  ]
  
  const router = createRouter({
    history:createWebHashHistory(),
    routes
  })
  export default router
